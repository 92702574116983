/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { L } from '../../../lib/abpUtility'; // TODO Remove L from ts files
import AppConsts from '../../../lib/appconst';
import type { ApiRequestOptions } from './ApiRequestOptions';
import type { OnCancel } from './CancelablePromise';
import { CancelablePromise } from './CancelablePromise';
const qs = require('qs');

declare var abp: any;

function getUrl(options: ApiRequestOptions): string {
  return options.url;
}

function getRequestBody(options: ApiRequestOptions): any {
  if (options.body) {
    return options.body;
  }
  return;
}

async function sendAspRequest(
  options: ApiRequestOptions,
  url: string,
  body: any,
  onCancel: OnCancel,
  token?: string
): Promise<AxiosResponse<any>> {
  const source = axios.CancelToken.source();

  const config: AxiosRequestConfig = {
    baseURL: AppConsts.remoteServiceBaseUrlWelfare,
    url,
    timeout: 60000,
    data: body,
    method: options.method,
    params: options.query,
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        encode: true,
      });
    },
  };

  onCancel(() => source.cancel('TheUserAbortedARequest'));

  axios.interceptors.request.use(
    function (config) {
      //config.headers.common = [];
      if (abp.auth.getToken()) {
        config.headers.Authorization = `Bearer ${abp.auth.getToken()}`;
      }

      config.headers['Abp-TenantId'] = abp.multiTenancy.getTenantIdCookie();

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        !!error.response &&
        !!error.response.data.error &&
        !!error.response.data.error.message &&
        error.response.data.error.details
      ) {
        console.error(error.response.data.error.details);
      } else if (
        !!error.response &&
        !!error.response.data.error &&
        !!error.response.data.error.message
      ) {
        console.error(error.response.data.error.message);
      } else if (!error.response) {
        console.log(L('UnknownError'));
      }

      setTimeout(() => {}, 1000);

      return Promise.reject(error);
    }
  );

  return await axios.request(config);
}

/**
 * Request using axios client
 * @param options The request options from the the service
 * @returns CancelablePromise<T>
 */
export function request<T>(
  OpenAPI: any,
  options: ApiRequestOptions
): CancelablePromise<T> {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = getUrl(options);
      const body = getRequestBody(options);
      if (!onCancel.isCancelled) {
        const response = await sendAspRequest(options, url, body, onCancel);
        const responseBody = response.data.result;

        resolve(responseBody);
      }
    } catch (error) {
      reject(error);
    }
  });
}
