/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedSearchInput } from '../models/AdvancedSearchInput';
import type { ItemIdResponseDto } from '../models/ItemIdResponseDto';
import type { PricingInputDto } from '../models/PricingInputDto';
import type { PricingResponseDto } from '../models/PricingResponseDto';
import type { ProposalsResponseAdvancedDto } from '../models/ProposalsResponseAdvancedDto';
import type { ProposalsResponseDto } from '../models/ProposalsResponseDto';
import type { SearchInputDto } from '../models/SearchInputDto';
import type { SelectionInputDto } from '../models/SelectionInputDto';
import type { TransportsProposalsResponseDto } from '../models/TransportsProposalsResponseDto';
import type { UpdateTransportInputDto } from '../models/UpdateTransportInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShoppingHdpServiceService {

    /**
     * @returns ItemIdResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingHdpServiceCreateSearch({
        requestBody,
    }: {
        requestBody?: SearchInputDto,
    }): CancelablePromise<ItemIdResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingHDPService/CreateSearch',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ProposalsResponseAdvancedDto Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingHdpServiceRetrieveProposals({
        searchId,
        requestBody,
    }: {
        searchId?: string,
        requestBody?: AdvancedSearchInput,
    }): CancelablePromise<ProposalsResponseAdvancedDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingHDPService/RetrieveProposals',
            query: {
                'searchId': searchId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ProposalsResponseDto Success
     * @throws ApiError
     */
    public static getApiServicesAppShoppingHdpServiceRetrieveAllProposals({
        searchId,
    }: {
        searchId?: string,
    }): CancelablePromise<ProposalsResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/ShoppingHDPService/RetrieveAllProposals',
            query: {
                'searchId': searchId,
            },
        });
    }

    /**
     * @returns ItemIdResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingHdpServiceCreatePricing({
        requestBody,
    }: {
        requestBody?: PricingInputDto,
    }): CancelablePromise<ItemIdResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingHDPService/CreatePricing',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns PricingResponseDto Success
     * @throws ApiError
     */
    public static getApiServicesAppShoppingHdpServiceRetrievePricing({
        pricingId,
    }: {
        pricingId?: string,
    }): CancelablePromise<PricingResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/ShoppingHDPService/RetrievePricing',
            query: {
                'pricingId': pricingId,
            },
        });
    }

    /**
     * @returns ItemIdResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingHdpServiceCreateSelection({
        requestBody,
    }: {
        requestBody?: SelectionInputDto,
    }): CancelablePromise<ItemIdResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingHDPService/CreateSelection',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns TransportsProposalsResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppShoppingHdpServiceGetAlternativeTransports({
        pricingId,
        requestBody,
    }: {
        pricingId?: string,
        requestBody?: AdvancedSearchInput,
    }): CancelablePromise<TransportsProposalsResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/ShoppingHDPService/GetAlternativeTransports',
            query: {
                'pricingId': pricingId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns PricingResponseDto Success
     * @throws ApiError
     */
    public static putApiServicesAppShoppingHdpServiceUpdateTransport({
        pricingId,
        requestBody,
    }: {
        pricingId?: string,
        requestBody?: UpdateTransportInputDto,
    }): CancelablePromise<PricingResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/services/app/ShoppingHDPService/UpdateTransport',
            query: {
                'pricingId': pricingId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
