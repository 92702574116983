import { makeAutoObservable, runInAction } from 'mobx';
import { HotelResultFilters } from '../../models/Romulo/HotelResultFilters';
import getSortKeyByTabIndex from '../../scenes/FlightsResult/utils/getSortKeyByTabIndex';
import {
  AdvancedSearchInput,
  ItemIdResponseDto,
  PricingInputDto,
  PricingResponseDto,
  ProposalsResponseAdvancedDto,
  ProposalsResponseDto,
  SearchInputDto,
  SelectionInputDto,
  ShoppingHdpServiceService,
  TransportsProposalsResponseDto,
  UpdateTransportInputDto,
} from '../../services/codegen-romulo';
import { getSearchParam } from '../../utils/getSearchParam';

export default class ShoppingSelectionStore {
  searchProposals?: ProposalsResponseAdvancedDto;
  allSearchProposals?: ProposalsResponseDto;
  hotelResultFilters?: HotelResultFilters = {};
  searchId?: string;
  hotelResultPage = Number(1);
  sortingIndex = String('RECOMMENDED');
  loadingHotelResults = true;
  pricingProposals?: PricingResponseDto;
  alternativeTransports?: TransportsProposalsResponseDto;
  pricingWithNewTransport?: PricingResponseDto;
  mapBoundingBox?: {
    from: {
      latitude: number;
      longitude: number;
    };
    to: {
      latitude: number;
      longitude: number;
    };
  };

  QPSearchId = getSearchParam('searchId');

  constructor() {
    makeAutoObservable(this);
  }

  createSearchId = async (requestBody: SearchInputDto): Promise<string> => {
    const response =
      await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceCreateSearch(
        { requestBody }
      );

    runInAction(() => {
      this.searchId = response?.id?.value;
    });

    return response?.id?.value;
  };

  getProposals = async () => {
    this.loadingHotelResults = true;
    const filters = this.hotelResultFilters;
    const priceRange =
      filters.budgetRange?.length > 0
        ? [
            {
              name: 'price-range',
              values: [
                filters.budgetRange[0].toString(),
                filters.budgetRange[1].toString(),
              ],
            },
          ]
        : [];

    const deals = filters.deals
      ? [
          {
            name: 'promotion',
            values: ['FLASH_SALE'],
          },
        ]
      : [];

    const selectedMealPlanKeys =
      filters.mealPlans &&
      filters.mealPlans.filter(
        (meal) =>
          (meal.selected === true || meal.allSelected) &&
          meal.disabled === false
      );

    const mealPlans =
      filters.mealPlans && selectedMealPlanKeys.length > 0
        ? [
            {
              name: 'meal-plan',
              values: selectedMealPlanKeys
                .filter((meal) => meal.planKey !== 'ALL_MEAL_PLANS')
                .map((meal) => meal.planKey),
            },
          ]
        : [];

    const amenities =
      filters.amenities && filters.amenities.length > 0
        ? [
            {
              name: 'amenities',
              values: filters.amenities,
            },
          ]
        : [];

    const coordinates = this.mapBoundingBox
      ? [
          {
            name: 'coordinates',
            boundingBox: this.mapBoundingBox,
          },
        ]
      : [];

    const characteristics =
      filters.characteristics && filters.characteristics.length > 0
        ? [
            {
              name: 'characteristics',
              values: filters.characteristics,
            },
          ]
        : [];

    const propertyTypes =
      filters.propertyType && filters.propertyType.length > 0
        ? [
            {
              name: 'property-type',
              values: filters.propertyType,
            },
          ]
        : [];

    const stars =
      filters.stars && filters.stars.length > 0
        ? [
            {
              name: 'stars',
              values: filters.stars,
            },
          ]
        : [];

    const freeCancellation = filters.hasFreeCancellation
      ? [
          {
            name: 'free-cancellation',
            values: ['true'],
          },
        ]
      : [];

    const sortKey = getSortKeyByTabIndex(this.sortingIndex);

    const getProposalsRequestBody: AdvancedSearchInput = {
      page: {
        number: this.hotelResultPage,
        size: 20,
      },
      facets: [
        ...priceRange,
        ...deals,
        ...mealPlans,
        ...freeCancellation,
        ...amenities,
        ...characteristics,
        ...propertyTypes,
        ...stars,
        ...coordinates,
      ],
      sort: sortKey,
    };

    const response =
      await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceRetrieveProposals(
        {
          searchId: this.searchId || this.QPSearchId,
          requestBody: getProposalsRequestBody,
        }
      );

    runInAction(() => {
      this.searchProposals = response;
      this.loadingHotelResults = false;
    });
  };

  getAllProposals = async (searchId: string): Promise<void> => {
    this.loadingHotelResults = true;
    const response =
      await ShoppingHdpServiceService.getApiServicesAppShoppingHdpServiceRetrieveAllProposals(
        {
          searchId,
        }
      );

    runInAction(() => {
      this.allSearchProposals = response;
      this.loadingHotelResults = false;
    });
  };

  createPricingId = async (requestBody: PricingInputDto): Promise<string> => {
    const response =
      await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceCreatePricing(
        { requestBody }
      );

    return response?.id?.value;
  };

  getPricing = async (pricingId: string): Promise<void> => {
    this.pricingProposals = {};
    const response =
      await ShoppingHdpServiceService.getApiServicesAppShoppingHdpServiceRetrievePricing(
        {
          pricingId,
        }
      );

    runInAction(() => {
      this.pricingProposals = response;
    });
  };

  createSelectionId = async (
    requestBody: SelectionInputDto
  ): Promise<ItemIdResponseDto> => {
    const response =
      await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceCreateSelection(
        { requestBody }
      );

    return response;
  };

  getAlternativeTransports = async (
    pricingId: string,
    requestBody: AdvancedSearchInput
  ): Promise<void> => {
    this.alternativeTransports = {};
    const response =
      await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceGetAlternativeTransports(
        {
          pricingId,
          requestBody,
        }
      );

    runInAction(() => {
      this.alternativeTransports = response;
    });
  };

  updateTransportId = async (
    pricingId: string,
    requestBody: UpdateTransportInputDto
  ): Promise<void> => {
    this.pricingWithNewTransport = {};
    const response =
      await ShoppingHdpServiceService.putApiServicesAppShoppingHdpServiceUpdateTransport(
        {
          pricingId,
          requestBody,
        }
      );

    runInAction(() => {
      this.pricingWithNewTransport = response;
    });
  };

  resetSearchId = () => {
    runInAction(() => {
      this.searchId = undefined;
    });
  };

  resetSearchProposals = () => {
    runInAction(() => {
      this.searchProposals = undefined;
    });
  };

  resetPricingProposals = () => {
    runInAction(() => {
      this.pricingProposals = undefined;
    });
  };

  resetAllProposals = () => {
    this.resetSearchProposals();
    this.resetPricingProposals();
  };

  updateHotelResultsFilters = async (filters: HotelResultFilters) => {
    this.hotelResultPage = 1;
    this.loadingHotelResults = true;
    this.hotelResultFilters = filters;
    await this.getProposals();
  };

  updateMapBoundingBox = async (boundingBox: {
    from: {
      latitude: number;
      longitude: number;
    };
    to: {
      latitude: number;
      longitude: number;
    };
  }) => {
    this.hotelResultPage = 1;
    this.loadingHotelResults = true;
    this.mapBoundingBox = boundingBox;
    await this.getProposals();
  };

  clearHotelResultsFilters = async () => {
    this.loadingHotelResults = true;
    this.hotelResultFilters = {};
    await this.getProposals();
  };

  updateSortingIndex = async (newIndex: string) => {
    this.loadingHotelResults = true;
    this.sortingIndex = newIndex;
    await this.getProposals();
  };

  updatePage = async (pageNumber: number) => {
    this.loadingHotelResults = true;
    this.hotelResultPage = pageNumber;
    await this.getProposals();
  };
}
