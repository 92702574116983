import {
  BackendFetch,
  DevTools,
  FormatSimple,
  Tolgee,
  TolgeeProvider,
} from '@tolgee/react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import './App.css';
import CookiesBanner from './components/CookiesBanner/CookiesBanner';
import Router from './components/Router';
import useExternalScripts from './hooks/useExternalScripts';
import { useBrandConfigStore } from './stores/Zustand/useStore';
import { baseTheme } from './utils/configurations/baseTheme';
import { localeObject } from './utils/localeObjects';
import { updateFavicon } from './utils/updateFavicon';

declare var abp: any;

const tolgeeStoreUrl =
  'https://tolgee-content-roiward.s3.eu-west-1.amazonaws.com/5eac5c47e7d33b38186cb35ad049c57d';

const tolgeeConfig = {
  apiUrl: window._env_.VITE_APP_TOLGEE_API_URL,
  apiKey: window._env_.VITE_APP_TOLGEE_API_KEY,
};

const tolgee = Tolgee()
  .use(DevTools())
  .use(
    BackendFetch({
      prefix: tolgeeStoreUrl,
    })
  )
  .use(FormatSimple());

const LmnConsent = (props: {
  language: string;
  country: string;
  brand: string;
}) => {
  useExternalScripts({
    url: 'https://assets.staticroot.com/cookie-consent-manager-ui/3/cookie-consent-manager.js',
    attributes: {
      id: 'cookie-consent-manager',
      'data-brand': props.brand,
      'data-country': props.country,
      'data-lang': props.language,
      'data-theme': props.brand,
      'data-host': 'https://assets.staticroot.com/cookie-consent-manager-ui/',
      'data-version': '3',
    },
  });
  return null;
};

const App = (): JSX.Element => {
  const brandConfig = useBrandConfigStore((s) => s.brandConfig);
  const { language, country, theme, brand } = brandConfig;
  const { primaryColor, primaryColorBg, secondaryColor, favicon } = theme;

  updateFavicon(favicon);

  useEffect(() => {
    dayjs.locale(language, localeObject[language]);
  }, []);

  const showCookiesConsent =
    abp.utils.getCookieValue('cookies-acceptance') !== 'true';

  return (
    <TolgeeProvider
      tolgee={tolgee.init({
        ...tolgeeConfig,
        language,
      })}
    >
      {brand.brandOrigin === 'Lastminute' ? (
        <LmnConsent
          language={language}
          country={country}
          brand={brand.brandBase}
        />
      ) : (
        showCookiesConsent && <CookiesBanner />
      )}
      <ConfigProvider
        theme={{
          token: {
            ...baseTheme.token,
            colorPrimary: primaryColor,
            colorPrimaryBg: primaryColorBg,
            colorFillQuaternary: secondaryColor,
          },
          components: {
            ...baseTheme.components,
            Slider: {
              colorPrimaryBorder: primaryColor,
              colorPrimaryBorderHover: primaryColor,
            },
            Pagination: {
              itemActiveBg: primaryColorBg,
            },
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </TolgeeProvider>
  );
};

export default App;
