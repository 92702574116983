import { useTranslate } from '@tolgee/react';
import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useBrandConfigStore } from '../../stores/Zustand/useStore';

declare var abp: any;

const ConsentWrapper = styled.div`
  position: fixed;
  z-index: 99999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  line-height: 16px;
  font-family: open sans;
  font-size: 12px;
  will-change: opacity;
  opacity: 1;
  transition: opacity 0.4s ease;
  align-items: flex-end;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 8, 8, 0.3);
    z-index: 1;
    pointer-events: auto;
  }
`;

const Title = styled.span`
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 24px;
  padding: 0 32px;
  color: #3c3c3c;
  line-height: 36px;
  margin-right: 22%;
`;

const Text = styled.div`
  padding: 0 32px;

  a {
    cursor: pointer;
    color: #8c8c92;
    text-decoration: underline;
  }
  button {
    color: #8c8c92;
    text-decoration: underline;
    font-size: inherit;
    font-family: inherit;
    border: none;
    background: 0 0;
    padding: 0;
    line-height: 16px;
    cursor: pointer;
  }
`;

const ConsentBanner = styled.div`
  position: fixed;
  z-index: 99999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  line-height: 16px;
  font-family: open sans;
  font-size: 12px;
  will-change: opacity;
  transition: opacity 0.4s ease;
  opacity: 1;
  align-items: flex-end;
`;
const ConsentContainer = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 1;
  overflow: hidden;
  transition: transform 0.4s ease;
  -webkit-font-smoothing: antialiased;
  padding: 24px;
  border-radius: 0 0;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: #8c8c92;
  overflow-y: auto;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  padding-bottom: 16px;
`;

const Button = styled.div`
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #000000;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 32px;
`;

const ConsentButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const CustomButtons = styled.div`
  margin-right: 16px;
`;

const ButtonPrimary = styled(Button)<{
  $mainColor: string;
  $textColor: string;
}>`
  background-color: ${({ $mainColor }) => $mainColor || undefined};
  color: ${({ $textColor }) => $textColor || undefined};
  padding: 10px 100px;
  border: 1px solid ${({ $textColor }) => $textColor || '#000000'};
`;

const ButtonSecondary = styled(Button)`
  color: #f2007d;
  background-color: transparent;
  padding: 10px 16px;
`;

const DenyButton = styled(ButtonSecondary)`
  position: absolute;
  top: 0;
  right: 32px;
`;

const CookiesBanner: FC = () => {
  const { t } = useTranslate();
  const brandConfig = useBrandConfigStore((s) => s.brandConfig);
  const headerColor = brandConfig.theme.headerColor;
  const primaryColorBgTextColor = brandConfig.theme.primaryColorBgTextColor;

  const [hideBanner, setHideBanner] = useState<boolean>(false);

  useEffect(() => {
    abp.utils.getCookieValue('cookies-acceptance') === 'true'
      ? setHideBanner(true)
      : setHideBanner(false);
  }, []);

  const acceptCookies = () => {
    abp.utils.setCookieValue(
      'cookies-acceptance',
      true,
      new Date(new Date().getTime() + 365 * 86400000),
      abp.appPath
    );
    setHideBanner(true);
  };

  if (hideBanner) {
    return <></>;
  }

  return (
    <ConsentWrapper>
      <ConsentBanner>
        <ConsentContainer>
          <ContentWrapper>
            <Content>
              <InfoWrapper>
                <Title>{t('cookie_consent.title')}</Title>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: t('cookie_consent.main_description'),
                  }}
                ></Text>
              </InfoWrapper>
              <ButtonWrapper>
                <ConsentButtons>
                  <ButtonPrimary
                    onClick={acceptCookies}
                    $mainColor={headerColor}
                    $textColor={primaryColorBgTextColor}
                  >
                    {t('cookie_consent.button.accept')}
                  </ButtonPrimary>
                </ConsentButtons>
              </ButtonWrapper>
            </Content>
          </ContentWrapper>
        </ConsentContainer>
      </ConsentBanner>
    </ConsentWrapper>
  );
};

export default CookiesBanner;
