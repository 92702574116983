import FlagProvider from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { StoreProvider } from './stores/Zustand/storeProvider';
import { StoreContext, stores } from './stores/stores';
import { brandConfig } from './utils/configurations/brandConfig';
import { unleashConfig } from './utils/configurations/unleashConfig';

dayjs.extend(utc);
dayjs.extend(timezone);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <FlagProvider config={unleashConfig}>
    <StoreProvider brandConfig={brandConfig}>
      <StoreContext.Provider value={stores}>
        <App />
      </StoreContext.Provider>
    </StoreProvider>
  </FlagProvider>
);
